import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import HowToModal from '../components/HowToModal/HowToModal';
import Reward from '../components/Reward';
import { addStag } from '../utils/utils';
import styled, { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import LogoCenterViolet from "../components/LogoCenter/LogoCenterViolet";
import WheelBlue from "../components/Wheel/WheelBlue";
import FooterBlue from "../components/FooterVariantA/FooterBlue";
import face from "../images/face.png";
import arrows from "../images/arrows.png";

const GlobalStyle = createGlobalStyle`
  ${reset}
  
  html, body {
    margin: 0;
    padding: 0;
    min-width: 100%;
    min-height: 100%;
  }
  body {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    background: radial-gradient(103.54% 124.25% at 0% 0%, #0094FF 0%, #004271 100%);
    color: #838FA3;
    overflow-x: hidden;
    min-width: 100%;
    min-height: 100%;
  }
  
  ::-webkit-scrollbar {
    width: 15px;
  }

  ::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: #0094FF;
  }

  ::-webkit-scrollbar-track {
    background: #004271;
  }
  
  img {
    user-drag: none;
    user-select: none;
  }
  
  .no-scroll {
    overflow-y: hidden;
  }

  @-webkit-keyframes arrowLeft {
    0% { left: 0; }
    50% { left: -50px; }
    100% { left: 0; }
  }

  @-webkit-keyframes arrowRight {
    0% { right: 0; }
    50% { right: -50px; }
    100% { right: 0; }
  }

  @-webkit-keyframes arrowTop {
    0% { top: 0; }
    50% { top: -30px; }
    100% { top: 0; }
  }
`;

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
`;

const WheelContainer = styled.div`
  max-width: 830px;
  width: 100%;
  margin: 0 auto;
  position: relative;
`;

const WheelWrapper = styled.div`
  width: auto;
  overflow: hidden;
  z-index: 200;
  margin-top: -100px;
  margin-bottom: -70px;

  @media screen and (max-width: 970px) {
    margin-top: 80px;
  }

  @media screen and (max-width: 750px) {
    margin-top: 80px;
  }
  
  @media screen and (max-width: 600px) {
    padding: 75px 0 0 0;
    margin-left: -20vw;
    margin-top: 0;
    width: 140vw;
    height: 140vw;
    
    > div {
      width: 140vw;
      height: 140vw;
    }
  }
`;

const FooterStick = styled.div`
  width: 100%;
  height: 182px;
  position: fixed;
  bottom: 0;
  left: 0;
  background: rgba(0, 132, 227, 0.6);
  z-index: 220;
`;

const FooterStickWrapper = styled.div`
  width: 100%;
  height: 170px;
  margin-top: 12px;
  background: #0094FF;
`;

const FooterStickInner = styled.div`
  max-width: 640px;
  width: 100%;
  padding: 25px 20px 0;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  overflow: hidden;
  @media screen and (max-width: 680px) {
    justify-content: center;
    padding: 25px 5px 0;
  }
`;
const FooterStickCol1 = styled.div`
  @media screen and (max-width: 680px) {
    display: none;
  }
`;
const FooterStickCol2 = styled.div``;
const FooterStickTitle1 = styled.div`
  font-weight: 700;
  font-size: 64px;
  line-height: 75px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
`;
const FooterStickTitle2 = styled.div`
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  color: #fff;
  text-align: center;
  white-space: nowrap;
`;

const ArrowLeft = styled.img`
  position: absolute;
  top: calc(50% - 38px);
  left: 10px;
  animation: arrowLeft 1.5s infinite;

  @media screen and (max-width: 970px) {
    display: none;
  }
`;
const ArrowRight = styled.img`
  position: absolute;
  transform: rotate(180deg);
  right: 10px;
  top: calc(50% - 38px);
  animation: arrowRight 1.5s infinite;

  @media screen and (max-width: 970px) {
    display: none;
  }
`;
const ArrowTop = styled.img`
  display: none;

  @media screen and (max-width: 970px) {
    display: block;
    position: absolute;
    transform: rotate(90deg);
    top: 0;
    left: calc(50% - 44px);
    animation: arrowTop 1.5s infinite;
  }

  @media screen and (max-width: 600px) {
    margin-top: 65px;
  }
`;

const LPBlue = () => {
  const [win, setWin] = useState(null);
  const [bonusInfo, setBonusInfo] = useState(false);

  const promoUrl = "";

  const pageContext = {
    "copyright": "© 2021 All Rights Reserved. Sportbet.one",
    "modalFailButtonText": "SPIN AGAIN",
    "modalFailMessage": "Luck isn't on your side",
    "modalFailSubtitle": "Not this time",
    "modalSuccessAverageMessage": "$10 Risk-Free Bet!",
    "modalSuccessButtonText": "GRAB NOW",
    "modalSuccessLowestMessage": "$5 Risk-Free Bet!",
    "modalSuccessMaxMessage": "$50 Risk-Free Bet!",
    "modalSuccessRetryButtonText": "SPIN AGAIN",
    "modalSuccessSubtitle": "You won:",
    "modalSuccessTitle": "CONGRATULATIONS!",
    "section1ButtonText": "GET STARTED",
    "section1Description": "Create your account instantly.",
    "section2Description": "Add crypto to your Sportbet.one wallet.",
    "section1Title": "SIGN UP",
    "section2Title": "DEPOSIT",
    "section3ButtonText": "Place bet",
    "section3Description": "Switch on “Place Risk-Free bet” and get up to $50 back if you lose",
    "section3Title": "BET",
    "sectionsButtonText": "Grab now",
    "sectionsTitle": "How to get a bonus?",
    "showTermsAndConditions": true,
    "slug": "whl-kenya-risk-free50-en",
    "subTitle": "Completely anonymous sports and casino betting!",
    "title": "Spin the weel and win a bonus!",
    "footerText": {
      "raw": "{\"data\":{},\"content\":[{\"data\":{},\"content\":[{\"data\":{},\"marks\":[],\"value\":\"New customers only Risk - Free Bet is for sports betting only and will be added to your wallet immediately after registration. Free bet is valid for 7 days. No cashout or wagering restrictions. Terms & Conditions apply. Please bet responsibly, begambleaware.org\",\"nodeType\":\"text\"}],\"nodeType\":\"paragraph\"}],\"nodeType\":\"document\"}"
    },
    "termsApplyButtonText": "APPLY",
    "termsAndConditionsTitle": "Terms & Conditions",
    "termsAndConditions": {
      "raw": "{\"data\":{},\"content\":[{\"data\":{},\"content\":[{\"data\":{},\"marks\":[],\"value\":\"- Risk-Free Bet amount will be refunded in case of loss.\",\"nodeType\":\"text\"}],\"nodeType\":\"paragraph\"},{\"data\":{},\"content\":[{\"data\":{},\"marks\":[],\"value\":\"- Offer is valid for new players only.\",\"nodeType\":\"text\"}],\"nodeType\":\"paragraph\"},{\"data\":{},\"content\":[{\"data\":{},\"marks\":[],\"value\":\"- Offer is limited to one per person, family, IP address, device, email address, or billing information. In case we suspect a breach of the rules (use of fake accounts, groups of players, etc.), SportBet reserves the right to cancel the Risk-Free Bet up to $50 offer and all the winnings within it.\",\"nodeType\":\"text\"}],\"nodeType\":\"paragraph\"},{\"data\":{},\"content\":[{\"data\":{},\"marks\":[],\"value\":\"- After participation in the Risk-Free Bet up to $50 promo, you will have 7 days to use your Risk-Free Bet.\",\"nodeType\":\"text\"}],\"nodeType\":\"paragraph\"},{\"data\":{},\"content\":[{\"data\":{},\"marks\":[],\"value\":\"- Sportbet.one reserves the right to withhold or cancel any Risk-Free Bets and winnings in cases where the Risk-Free Bet has been mistakenly accrued, or in cases where the abuse by the player has been identified.\",\"nodeType\":\"text\"}],\"nodeType\":\"paragraph\"}],\"nodeType\":\"document\"}"
    },
    "wheelAverageBonusText": "Risk-Free Bet!",
    "wheelAverageBonusTitle": "$10",
    "wheelLowestBonusText": "Risk-Free Bet!",
    "wheelAverageBonusUrl": "https://sportbet.one/?promo=60bfeeade92e6249eede9032",
    "wheelLowestBonusTitle": "$5",
    "wheelLowestBonusUrl": "https://sportbet.one/?promo=60bfeeade92e6249eede9032",
    "wheelMaxBonusText": "Risk-Free Bet!",
    "wheelMaxBonusTitle": "$50",
    "wheelMaxBonusUrl": "https://sportbet.one/?promo=5ffc5844da053d1b47cf4d74",
    "wheelSpinText": "SPIN",
    "wheelTryAgainText": "TRY AGAIN",
  };

  useEffect(() => {
    if (typeof document === "undefined") return;
    const linkClick = (e) => {
      const href = e.currentTarget.href;
      if (href.includes("promo=")) {
        e.preventDefault();
        window.location.href = addStag(href);
      }
    };

    document.querySelectorAll("a").forEach(x => x.addEventListener("click", linkClick));
    return () => {
      document.querySelectorAll("a").forEach(x => x.removeEventListener("click", linkClick));
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Sportbet.one</title>
      </Helmet>
      <GlobalStyle />
      {!bonusInfo && win ? <Reward pageContext={pageContext} win={win} onClose={() => { setWin(null); }} openBonus={() => setBonusInfo(true)} isViolet={true} /> : null}
      {bonusInfo && win ? <HowToModal win={win} onClose={() => { setBonusInfo(false); }} {...pageContext} /> : null}
      <Container>
        <WheelContainer>
          <ArrowTop src={arrows} />
          <ArrowLeft src={arrows} />
          <ArrowRight src={arrows} />
          <WheelWrapper>
            <WheelBlue setWin={setWin} {...pageContext} />
          </WheelWrapper>
        </WheelContainer>
        <LogoCenterViolet promoUrl={promoUrl} />
        <FooterBlue />
        <FooterStick>
          <FooterStickWrapper>
            <FooterStickInner>
              <FooterStickCol1>
                <img src={face} />
              </FooterStickCol1>
              <FooterStickCol2>
                <FooterStickTitle1>Spin & Win</FooterStickTitle1>
                <FooterStickTitle2>$50 Risk-Free Bet!</FooterStickTitle2>
              </FooterStickCol2>
              <FooterStickCol1>
                <img src={face} />
              </FooterStickCol1>
            </FooterStickInner>
          </FooterStickWrapper>
        </FooterStick>
      </Container>
    </>
  );
};

export default LPBlue;
